require.context('../img/', true, /\.(gif|jpg|png|svg|eot|ttf|woff|woff2)$/i);

require("@rails/ujs").start();

import 'jquery';
import 'bootstrap';
import './backoffice/components/flash';

import { Application } from "stimulus"
import Turbolinks from 'turbolinks';
import { definitionsFromContext } from "stimulus/webpack-helpers"

Turbolinks.start();

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


