import { Controller } from "stimulus"
import EventBus from '../lib/events'

export default class extends Controller {
  static targets = ["button"]
  static values = { urls: Array }

  connect() {
    this.element[this.identifier] = this
  }

  urlsValueChanged() {
    if (this.urlsValue.length > 0) {
      this.buttonTarget.innerText = `Download (${this.urlsValue.length})`
      this.buttonTarget.removeAttribute("disabled")
    } else {
      this.buttonTarget.innerText = ``
      this.buttonTarget.setAttribute("disabled", "disabled")
    }
  }

  add(url) {
    var arr = this.urlsValue
    arr.push(url)
    this.urlsValue = arr
  }

  remove(url) {
    var arr = this.urlsValue
    this.urlsValue = arr.filter(item => item.url !== url)
  }

  async click(e) {
    if (this.urlsValue.length > 0) {
      e.preventDefault();
      this.buttonTarget.disabled = "disabled"

      window.open(`/retailsearch/download?${this.urlsValue.map(item => `file[][url]=${encodeURI(item.url)}&file[][website]=${encodeURI(item.website.replace('&', ''))}`).join("&")}`, "_blank")

      this.urlsValue = []

      EventBus.emit('reset')

      this.buttonTarget.innerText = `Select to download`
    }
  }


}
