/*
  Manages Flash messages display and removal
*/
jQuery(document).ready(function($) {
    const $flashMessages = $(
        $("#js-flashContainer .alert")
            .get()
            .reverse()
    );
    const animationDuration = 300;
    const staggerDuration = 300;
    const autoRemoveDuration = 10000;

    const removeFlashMessage = $element => {
        clearTimeout($element.removalTimeout);
        $element.removeClass("visible");
        setTimeout(() => $element.remove(), animationDuration);
    };

    const initFlashMessage = $element => {
        $element.addClass("visible");
        $element.removalTimeout = setTimeout(
            () => removeFlashMessage($element),
            autoRemoveDuration
        );
        $element.find("button").click(() => removeFlashMessage($element));
    };

    const initFlashMessages = () => {
        $flashMessages.each((index, flashEl) => {
            setTimeout(() => initFlashMessage($(flashEl)), staggerDuration * index);
        });
    };

    if ($flashMessages.length > 0) {
        initFlashMessages();
    }
});