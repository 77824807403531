import { Controller } from "stimulus"
import EventBus from '../lib/events'

export default class extends Controller {
  static values = { url: String, website: String }
  static targets = ["checkbox", "overlayCheckbox"]

  connect() {
    EventBus.on('reset', () => {
      this.checkboxTarget.style.opacity = 0;
      this.overlayCheckboxTarget.style.opacity = 1;
    })
  }

  toggle(e) {
    e.preventDefault();

    let downloader = document.getElementById("downloader").downloader;

    if (downloader.urlsValue.filter(item => item.url === this.urlValue).length > 0) {
      downloader.remove(this.urlValue)
      this.checkboxTarget.style.opacity = 0;
      this.overlayCheckboxTarget.style.opacity = 1;
    } else {
      downloader.add({ url: this.urlValue, website: this.websiteValue })
      this.checkboxTarget.style.opacity = 1;
      this.overlayCheckboxTarget.style.opacity = 0;
    }
  }

}