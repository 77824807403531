import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

  extract() {
    const filters = Cumulio.getFilters()
    document.getElementById("img-container").innerHTML = '<div style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>';

    Rails.ajax({
      type: "POST",
      url: "/pics_extractor",
      dataType: 'html',
      data: new URLSearchParams({ 'filters': JSON.stringify(filters) }).toString(),
      success: function (response) {
        document.getElementById("img-container").innerHTML = response.html //(img);
      },
    })
  }

}